
// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/mixins";

// Your variable + partials overrides
@import "variables";
@import "user-fonts";
@import "type";
@import "hamburger";
@import "bootstrap-offcanvas";
@import "icons";

// CUSTOM APP STYLES

.bg-secondary-light{
  background-color: lighten($secondary,30);
}
.header{
  position: absolute;
  top:0;
  width: 100%;
  transition: top 0.3s;
  background-color: rgba($white,1);
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
  @include media-breakpoint-up(lg) {
    box-shadow: none;
    background-color: transparent;
    &.hover{
      background-color: rgba($white,0.8);
      box-shadow: none;
    }
  }


}
.logo{
  max-width: 250px;
}
h2{
  position: relative;
  &:after{
    position: absolute;
    content: " ";
    width: 200px;
    height: 1px;
    left: 50%;
    bottom: -.5rem;
    background-color: #000;
    margin-left: -100px;
  }
  .bg-primary &{
    &:after{
      background-color: #fff;
    }
  }
}
.navbar-nav a {
  color: $gray-600;
  transition: all 100ms ease-in-out;
  text-decoration: none;
  padding: .15rem 0;
  font-size: $font-size-sm;
  &.active{
    color: $gray-900;
    text-decoration: none;
  }
}
section[id^="list-item-"]{
  margin-bottom: 4rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 10rem;
  }
}

.service-collapse{
  a{
    text-decoration: none;
    padding: .25rem 0;
    font-weight: bold;
    &:hover{
      text-decoration: none;
    }
    svg{
      transform: rotate(45deg);
    }
    &.collapsed{
      svg{
        transform: rotate(0)
      }
    }
  }
}
.icon{
  &.icon-close{
    transform: rotate(45deg);
  }
}

// Bootstrap and its default variables
@import '../../node_modules/bootstrap/scss/bootstrap';