@font-face {
  font-family: 'Lab Grotesque';
  src: url('fonts/LabGrotesque-Regular.eot');
  src: local('Lab Grotesque Regular'), local('LabGrotesque-Regular'),
      url('fonts/LabGrotesque-Regular.eot?#iefix') format('embedded-opentype'),
      url('fonts/LabGrotesque-Regular.woff2') format('woff2'),
      url('fonts/LabGrotesque-Regular.woff') format('woff'),
      url('fonts/LabGrotesque-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Lab Grotesque';
  src: url('LabGrotesque-Bold.eot');
  src: local('Lab Grotesque Bold'), local('LabGrotesque-Bold'),
      url('LabGrotesque-Bold.eot?#iefix') format('embedded-opentype'),
      url('LabGrotesque-Bold.woff2') format('woff2'),
      url('LabGrotesque-Bold.woff') format('woff'),
      url('LabGrotesque-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Lab Grotesque Mono';
  src: url('fonts/LabGrotesqueMono.eot');
  src: local('Lab Grotesque Mono'), local('LabGrotesqueMono'),
      url('fonts/LabGrotesqueMono.eot?#iefix') format('embedded-opentype'),
      url('fonts/LabGrotesqueMono.woff2') format('woff2'),
      url('fonts/LabGrotesqueMono.woff') format('woff'),
      url('fonts/LabGrotesqueMono.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
