.hamburger {
  position: absolute;
  top:0;
  right: 0;
  display: none;
  width: 4rem;
  height: 4rem;
  // transition: 0.5s all ease;
  cursor: pointer;
  .lines {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    cursor: pointer;
  }
  span {
    display: block;
    width: 26px;
    height: 3px;
    background: $primary;
    transition: all .3s;
    position: relative;
  }
  span + span {
    margin-top: 4px;
  }
  span:nth-child(3) {
    width: 13px;
  }

  .active span:nth-child(1) {
    animation: ease .5s top forwards;
  }

  .not-active span:nth-child(1) {
    animation: ease .5s top-2 forwards;
  }

  .active span:nth-child(2) {
    animation: ease .5s scaled forwards;
  }

  .not-active span:nth-child(2) {
    animation: ease .5s scaled-2 forwards;
  }

  .active span:nth-child(3) {
    animation: ease .5s bottom forwards;
    width: 26px;
  }

  .not-active span:nth-child(3) {
    animation: ease .5s bottom-2 forwards;
  }
}


@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 5px;
    transform: rotate(0);
  }
  100% {
    top: 5px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 5px;
    transform: rotate(45deg);
  }
  50% {
    top: 5px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 10px;
    transform: rotate(0);
  }
  100% {
    bottom: 10px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 10px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 10px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}