$offcanvas-width-m:   280px !default;
$offcanvas-width-d:   420px !default;
$offcanvas-media:     80000px !default; // var(--breakpoint-xl)
@media (max-width: $offcanvas-media) {
  .hamburger{
    display: block;
    z-index:1030;
  }
  .page-overlay{
    opacity: 0;
  }
  .offcanvas-stop-scrolling {
    height: 100%;
    overflow: hidden;
    // darken the page wrapper content
    .page-overlay{
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5);
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index:1027;
    }
  }
  .navbar-offcanvas {
    position: fixed;
    width: 100%;
    max-width: $offcanvas-width-m;
    height: 100%;
    left: -$offcanvas-width-m;
    top: 0;
    z-index:1028;
    transition: all .25s ease;
    background-color: $white;
    @include media-breakpoint-up(lg) {
      max-width: $offcanvas-width-d;
      left: -$offcanvas-width-d;
    }
    .navbar-offcanvas-header{
      padding:0 1rem;
      min-height: 3rem;
      a{
        &:hover{
          text-decoration: none;
          color: $gray-300;
        }
      }
      .icon{
        width: 2rem;
        height: 2rem;
      }
      @include media-breakpoint-down(sm) {
        min-height: 3.125rem;
      }
      .navbar-offcanvas-close{
        position: absolute;
        top:0;
        right: 0;
        padding: .5rem;
        width: 3rem;
        height: 3rem;
      }
    }
    &.in{
      .navbar-offcanvas-close{
        right: .5rem;
      }
    }
    .navbar-offcanvas-content{
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(100% - 3rem);
      font-size: $font-size-lg;
    }
    &.navbar-offcanvas-fade {
      opacity: 0;

      &.in {
        opacity: 1;
      }
    }

    &.offcanvas-transform {
      &.in {
        box-shadow: 4px 0 10px 0 rgba($black,.4);
        transform: translateX($offcanvas-width-m);
        @include media-breakpoint-up(lg) {
          transform: translateX($offcanvas-width-d);
        }
      }
    }

    &.offcanvas-position {
      &.in {
        left: 0;
      }
    }
    &.navbar-offcanvas-right {
      left: auto;
      right: -$offcanvas-width-m;
      @include media-breakpoint-up(lg) {
          right: -$offcanvas-width-d;
        }

      &.offcanvas-transform.in {
        transform: translateX(-$offcanvas-width-m);
        @include media-breakpoint-up(lg) {
          transform: translateX(-$offcanvas-width-d);
        }
      }

      &.offcanvas-position.in {
        left: auto;
        right: 0;
      }
    }

    .dropdown {
      &.open .caret {
        border-top: 0;
        border-bottom: 4px solid;
      }
    }

    .dropdown-menu {
      box-shadow: none;
      transition: height .25s ease-in;

      &.shown {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

