$enable-responsive-font-sizes: true;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$primary: #00542B;
$secondary: #C1A445;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                $font-size-base * .875;

$badge-font-size:                   $font-size-sm;
$badge-font-weight:                 normal;
$badge-padding-y:                   .25em;
$badge-padding-x:                   .4em;
$badge-border-radius:               3px;

$font-family-serif:       "Lab Grotesque Mono", "Time New Roman", Garamond, serif;
$font-family-sans-serif:  "Lab Grotesque", Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-family-heading:     "Lab Grotesque", Arial, "Helvetica Neue", Helvetica, sans-serif;

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 3.5),
    7: ($spacer * 4),
    8: ($spacer * 5),
    9: ($spacer * 6)
  ),
  $spacers
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 990px,
  xl: 1130px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1160px,
  xl: 1240px
);